<template>
    <div id="working-hours-bar-chart">
        <columnChart v-if="hasEnoughDataForChart" :data="chartData" :options="chartOptions" :style="chartStyle"/>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import '@toast-ui/chart/dist/toastui-chart.min.css';
import { columnChart } from '@toast-ui/vue-chart';

export default {
    name: 'WorkingHoursBarChart',
    components: { columnChart },
    data() {        
        return {
            chartData: {
                categories: [],
                series: [],
            },
            chartOptions: {
                chart: {
                    title: 'Gewerkte uren per week',
                },
                legend: {
                    visible: false,
                },
                yAxis: {
                    title: '',
                },
                xAxis: {
                    title: '',
                },
                series: {
                    showDot: false,
                    zoomable: true,
                    stack: true,
                },
                theme: {
                    series: {
                        barWidth: 10,
                        colors: ['#1da025', '#a0251d'],
                    },
                },
            },
            chartStyle: {
                width: `${this.chartWidth}px`,
                height: '300px'
            },
        }
    },
    computed: {
        ...mapState('dashboard', ['hoursForCustomers']),
        chartWidth() {
            return window.innerWidth - 48
        },
        hasEnoughDataForChart() {
            if (this.hoursForCustomers) {
                return this.hoursForCustomers.allWeeks.customers.length || this.hoursForCustomers.allWeeks.other.
                length
            }
            return false
        },
    },
    methods: {
        getLabelAndSerieData() {
            const currentWeek = this.getLatestWeekWithData()
            const mylabels = new Array(currentWeek).fill(0).map((item, index) => index + 1)
            const mycustomerData = new Array(currentWeek).fill(0)
            const myotherData = new Array(currentWeek).fill(0)
            this.hoursForCustomers.allWeeks.customers.forEach(item => {
                mycustomerData[item.weeknumber-1] = parseFloat(item.total)/60
            })
            this.hoursForCustomers.allWeeks.other.forEach(item => {
                myotherData[item.weeknumber-1] = parseFloat(item.total)/60
            })
            return {mylabels, mycustomerData, myotherData}
        },
        getLatestWeekWithData() {
            const uniqueWeeknumbersSet = new Set([
                ...(new Set(this.hoursForCustomers.allWeeks.customers.map(item => item.weeknumber))),
                ...(new Set(this.hoursForCustomers.allWeeks.other.map(item => item.weeknumber))),
            ])
            return Math.max(...uniqueWeeknumbersSet)
        },
    },
    mounted() {
        if (this.hasEnoughDataForChart) {
            const {mylabels, mycustomerData, myotherData} = this.getLabelAndSerieData()
            this.chartData.categories = mylabels
            this.chartData.series.push({name: 'customerData', data: mycustomerData})
            this.chartData.series.push({name: 'otherData', data: myotherData})
        }
    },
}
</script>

<style lang="less" scoped>
#working-hours-bar-chart {
    margin-top: 170px;
}
</style>