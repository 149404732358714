<template>
    <div id="dashboard">
        <p v-if="getDataError" class="text-danger">Dashboard data kon niet opgehaald worden</p>
        <p class="renew" v-show="refreshingStaleData">Dashboard vernieuwen...</p>
        <p class="renew" v-show="!refreshingStaleData">Laatste update <b>{{ dataFetchTime }}</b></p>
        <div id="statblocks">
            <header class="block-header">Gewerkte uren en omzet</header>
            <GeneralBlock :header="'Vandaag'" :amount="hours.day" :loading="loading" :color="'#d1ecd3'">
                <BottomBar :loading="loading" :revenue="revenue.day">
                    <template #leftamount><span>{{ `€ ${parseFloat(revenue.day[0]).toFixed(0)}` }}</span></template>
                    <template #rightamount><span>{{ `€ ${parseFloat(revenue.day[1]).toFixed(0)}` }}</span></template>
                </BottomBar>
            </GeneralBlock>
            <GeneralBlock :header="'Deze week'" :amount="hours.week" :loading="loading" :color="'#e8f5e9'">
                <BottomBar :loading="loading" :revenue="revenue.week">
                    <template #leftamount><span>{{ `€ ${parseFloat(revenue.week[0]).toFixed(0)}` }}</span></template>
                    <template #rightamount><span>{{ `€ ${parseFloat(revenue.week[1]).toFixed(0)}` }}</span></template>
                </BottomBar>
            </GeneralBlock>
            <GeneralBlock :header="'Deze maand'" :amount="hours.month" :loading="loading" :color="'#e8f5e9'">
                <BottomBar :loading="loading" :revenue="revenue.month">
                    <template #leftamount><span>{{ `€ ${parseFloat(revenue.month[0]).toFixed(0)}` }}</span></template>
                    <template #rightamount><span>{{ `€ ${parseFloat(revenue.month[1]).toFixed(0)}` }}</span></template>
                </BottomBar>
            </GeneralBlock>
            <GeneralBlock :header="'Dit jaar'" :amount="hours.ytd" :loading="loading" :color="'#d1ecd3'">
                <BottomBar :loading="loading" :revenue="revenue.year">
                    <template #leftamount><span>{{ `€ ${parseFloat(revenue.year[0]).toFixed(0)}` }}</span></template>
                    <template #rightamount><span>{{ `€ ${parseFloat(revenue.year[1]).toFixed(0)}` }}</span></template>
                </BottomBar>
            </GeneralBlock>
        </div>

        <div id="hours_comparison">
            <header class="block-header">Uren voor klanten</header>
            <GeneralBlock :header="'Deze week'" :amount="hoursForCustomers === null ? 0 : hoursForCustomers.currentWeek" :loading="loading" :color="'#d1ecd3'">
                <BottomBar :loading="loading" :revenue="revenue.week">
                    <template #leftamount><span>{{ `€ ${parseFloat(revenue.week[0]).toFixed(0)}` }}</span></template>
                    <template #rightamount><span>{{ `€ ${parseFloat(revenue.week[1]).toFixed(0)}` }}</span></template>
                </BottomBar>
            </GeneralBlock>
            <GeneralBlock :header="'Weekgemiddelde'" :amount="averageHoursPerWeekForCustomers" :loading="loading" :color="'#e8f5e9'">
                <BottomBar :loading="loading" :revenue="revenue.year">
                    <template #leftamount><span>{{ `€ ${parseFloat(revenue.year[0]).toFixed(0)}` }}</span></template>
                    <template #rightamount><span>{{ `€ ${parseFloat(revenue.year[1]).toFixed(0)}` }}</span></template>
                </BottomBar>
            </GeneralBlock>
        </div>

        <WorkingHoursBarChart v-if="!loading" />
    </div>
</template>

<script>
import { localDateStringNow } from '@/libraries/dates'
import { mapState, mapGetters, mapActions } from 'vuex'
import GeneralBlock from '@/components/GeneralBlock'
import BottomBar from '@/components/BottomBar'
import WorkingHoursBarChart from '@/components/WorkingHoursBarChart'
export default {
    name: 'Dashboard',
    components: {GeneralBlock, BottomBar, WorkingHoursBarChart},
    data() {
        return {
            loading: false,
            refreshingStaleData: false,
            getDataError: null,
            dataFetchTime: null,
        }
    },
    computed: {
        ...mapState('dashboard', ['hours', 'hoursForCustomers', 'todayString', 'dataAvailable']),
        ...mapGetters('dashboard', ['revenue']),
        averageHoursPerWeekForCustomers() {
            if (this.hoursForCustomers && this.hoursForCustomers.hasOwnProperty('allWeeks') && this.hoursForCustomers.allWeeks.customers.length) {
                const hoursPerWeek = this.hoursForCustomers.allWeeks.customers.map(week => parseFloat(week.total)/60)
                // compute sum using reduce and then divide by length for average
                return (hoursPerWeek.reduce((a, b) => a + b, 0)/hoursPerWeek.length).toFixed(1) || 0
            }
            return 0
        },
    },
    methods: {
        ...mapActions('dashboard', ['getData']),
    },
    mounted() {
        // retrieve data with loading spinners
        // or in the background if data was already retrieved eralier on
        if (this.dataAvailable) {
            this.refreshingStaleData = true
        } else {
            this.loading = true
        }
        
        this.getData()
        .then(() => this.dataFetchTime = localDateStringNow()) 
        .catch(err => this.getDataError = true)
        .finally(() => {
            this.loading = false
            this.refreshingStaleData = false
        })
    },
};
</script>

<style scoped lang="less">
#statblocks {
    overflow: hidden;
}

p.renew {
    margin-top: 6px;
    margin-bottom: 5px;
    color: #555;
    text-align: right;
    font-size: 12px;
}

#dashboard {
    margin-top: -24px;
}

#hours_comparison {
    margin-top: 48px;
}
</style>
