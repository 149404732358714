<template>
    <div v-if="!loading" class="revenue">
        <span class="revenue-me">
            <slot name="lefticon"><i class="fa fa-user"></i></slot>
            <slot name="leftamount"><span v-text="revenueMe"></span></slot>
        </span>
        <span class="revenue-company">
            <slot name="righticon"><i class="fa fa-building"></i></slot>
            <slot name="rightamount"><span v-text="revenueAll"></span></slot>
        </span>
    </div>
</template>

<script>
    export default {
        name: 'BottomBar',
        props: {
            loading: {
                type: Boolean,
                required: true,
            },
            revenue: {
                type: Array,
                required: true,
            }
        },
        computed: {
            revenueMe() {
                return parseFloat(this.revenue[0]).toFixed(0)
            },
            revenueAll() {
                return parseFloat(this.revenue[1]).toFixed(0)
            },
        },
    }
</script>

<style lang="less" scoped>
.revenue {
    position: absolute;
    width: 100%;
    bottom: 0;
    background-color: @secondary;
    color: white;
    padding: 4px 6px;
    font-size: 15px;

    .revenue-company {
        float: right;
    }

    i {
        margin-right: 5px;
    }
}
</style>