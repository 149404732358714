<template>
    <div class="general-block" :style="styling">
        <div class="heading" v-text="header"></div>
        <div class="amount">
            <i v-if="loading" class="fa fa-spinner fa-spin"></i>
            <span v-else v-text="amount"></span>
        </div>
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: 'GeneralBlock',
        props: ['header', 'amount', 'loading', 'color'],
        computed: {
            styling() {
                return {
                    backgroundColor: this.color
                }
            },
        },
    };
</script>

<style scoped lang="less">
.general-block {
    position: relative;
    height: 144px;
    width: 50%;
    display: block;
    float: left;
    color: @textcolor;

    .heading {
        position: absolute;
        padding: 6px 0 0 6px;
    }

    .amount {
        font-size: 36px;
        font-weight: bold;
        line-height: 140px;
        text-align: center;
    }
}
</style>
